import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Spinner } from 'bv-components';
import ContactUsFooter from 'SharedComponents/contact_us_footer';
import { fetchSettings as fetchSettingsFn } from '../../../duck';
import GamblingSupportProvider from './gambling_support_provider';

const GamblingSupport = ({
  settings,
  gamblingSupportSettingsLoaded,
  fetchSettings,
}) => {
  useEffect(() => {
    if (!gamblingSupportSettingsLoaded) {
      fetchSettings();
    }
  }, []);

  if (!gamblingSupportSettingsLoaded) return <Spinner />;

  return (
    <div className="sg-portal-section--gambling-support">
      {settings.gamblingSupport.map((provider) => (
        <GamblingSupportProvider key={provider.id} {...provider} />
      ))}
      <ContactUsFooter />
    </div>
  );
};

GamblingSupport.propTypes = {
  settings: PropTypes.instanceOf(Object).isRequired,
  gamblingSupportSettingsLoaded: PropTypes.bool.isRequired,
  fetchSettings: PropTypes.func.isRequired,
};

const mapStateToProps = ({
  saferGamblingPortal: {
    settings,
    gamblingSupportSettingsLoaded,
  },
}) => ({
  settings,
  gamblingSupportSettingsLoaded,
});

const mapDispatchToProps = (dispatch) => ({
  fetchSettings: () => dispatch(fetchSettingsFn({
    settingsType: 'gambling_support',
    loadingStatusProp: 'gamblingSupportSettingsLoaded',
  })),
});

export default connect(mapStateToProps, mapDispatchToProps)(GamblingSupport);
