import PropTypes from 'prop-types';
import { sanitize } from 'dompurify';
import ContactUsFooter from 'SharedComponents/contact_us_footer';

const GenericSection = ({ title, longDescription }) => (
  <div className="sg-portal-section--generic">
    <div className="sg-portal-section__wrapper">
      <h1 className="bvs-header">{title}</h1>
      {longDescription.split('\n').map((line, index) => (
        <p
          id={index}
          className="bvs-p"
          dangerouslySetInnerHTML={{ __html: sanitize(line) }}
        />
      ))}
    </div>
    <ContactUsFooter />
  </div>
);

GenericSection.propTypes = {
  title: PropTypes.string.isRequired,
  longDescription: PropTypes.string.isRequired,
};

export default GenericSection;
