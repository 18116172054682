import PropTypes from 'prop-types';
import { Card, Button } from 'bv-components';
import { t } from 'bv-i18n';

const GamblingSupportProvider = (props) => {
  const {
    name,
    logo,
    description,
    contact_details: contactDetails,
    link,
  } = props;

  return (
    <div className="sg-portal-support">
      <h2 className="bvs-header sg-portal-support__title">{name}</h2>
      <Card>
        <img src={logo} alt={name} />
        <p>{description}</p>
        <div className="sg-portal-support__contact">
          {contactDetails.split('\n').map((line, index) => (
            <p id={index}>{line}</p>))}
        </div>
        <Button
          inverse
          secondary
          className="sg-portal-support__button"
          label={t('javascript.sg.portal.GAMBLING_SUPPORT.visit_website')}
          href={link}
          target="_blank"
        />
      </Card>
    </div>
  );
};

GamblingSupportProvider.propTypes = {
  name: PropTypes.string.isRequired,
  logo: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  contact_details: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
};

export default GamblingSupportProvider;
