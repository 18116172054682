import { createSelector } from 'reselect';

const limitHistoryDomain = createSelector(
  (state) => state.limitHistory,
  (_, limitType) => limitType,
  (limitHistory, limitType) => limitHistory[limitType],
);

export const getLimitLabels = createSelector(
  limitHistoryDomain,
  (substate) => (substate?.limits ? Object.keys(substate.limits) : null),
);

export const getGroupedLimits = createSelector(
  limitHistoryDomain,
  (substate) => substate?.limits,
);

export const getHasMore = createSelector(
  limitHistoryDomain,
  (substate) => substate?.meta?.last === false,
);

export const getCurrentPage = createSelector(
  limitHistoryDomain,
  (substate) => substate?.meta?.page || 0,
);

export const getEnabled = createSelector(
  limitHistoryDomain,
  (substate) => substate?.enabled || false,
);
