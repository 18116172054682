import { getJSON, postJSON } from 'bv-fetch';

const basePath = '/api/net_deposit_limit';

export const fetchCurrentLimits = () => getJSON(`${basePath}/account_limits`);
export const fetchLimitOptions = () => getJSON(`${basePath}/account_options`);

export const setNewLimit = (amount, period) => (
  postJSON(`${basePath}/account_limits`, { amount, period })
);

export const confirmLimit = () => getJSON(`${basePath}/account_limits/confirm`);
export const cancelLimit = () => getJSON(`${basePath}/account_limits/cancel`);
