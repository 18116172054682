import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { t } from 'bv-i18n';
import { Card, Button, Spinner } from 'bv-components';
import {
  getGroupedLimits, getLimitLabels, getHasMore, getEnabled,
} from '../selectors';
import { loadInitialHistory } from '../duck';
import ChangeList from './change_list';

const CardSection = ({
  type,
}) => {
  const dispatch = useDispatch();
  const isEnabled = useSelector((state) => getEnabled(state, type));
  const limitLabels = useSelector((state) => getLimitLabels(state, type));
  const groupedLimits = useSelector((state) => getGroupedLimits(state, type));
  const hasMore = useSelector((state) => getHasMore(state, type));

  useEffect(() => {
    if (isEnabled && !limitLabels) {
      dispatch(loadInitialHistory(type));
    }
  }, [isEnabled, limitLabels]);

  if (!isEnabled) return null;

  if (!limitLabels) return <Spinner />;

  return (
    <div className="limit-history">
      <h1 className="bvs-header">{t('sg.portal.limit_history.title')}</h1>
      {limitLabels.length ? (
        <Card>
          <ChangeList groupLabels={limitLabels} groupedEntries={groupedLimits} />
          {hasMore && (
            <div className="show-more-button">
              <Button
                primary
                type="button"
                to={`/safer_gambling/portal/limit_history/${type}`}
                label={t('sg.portal.limit_history.show_all')}
              />
            </div>
          )}
        </Card>
      ) : (
        <p>{t('sg.portal.limit_history.empty')}</p>
      )}
    </div>
  );
};

CardSection.propTypes = {
  type: PropTypes.oneOf([
    'deposit_limits',
    'loss_limits',
    'stake_limits',
  ]).isRequired,
};

export default CardSection;
