import { locale } from 'bv-i18n';
import { dateText, timeText } from 'AccountHistory/helpers/date_text';
import { toCurrency } from 'bv-helpers/number';

export const limitType = {
  DEPOSIT_LIMITS: 'deposit_limits',
  LOSS_LIMITS: 'loss_limits',
  STAKE_LIMITS: 'stake_limits',
};

const formatMonthLabel = (date) => new Intl.DateTimeFormat(
  locale(),
  { month: 'long', year: 'numeric' },
).format(new Date(date));

export const formatDate = (date) => `${timeText(date)}, ${dateText(date)}`;

export const formatAmount = ({ period, amount }) => {
  if (period) {
    if (Number.isInteger(amount)) {
      return `${toCurrency(amount)} ${period}`;
    }

    return period;
  }

  return toCurrency(amount);
};

export const groupLimitsByMonth = (ungroupedLimits, groupedLimits = {}) => (
  ungroupedLimits.reduce((acc, limit) => {
    const label = formatMonthLabel(limit.createdAt);

    acc[label] = [
      ...(acc[label] || []),
      limit,
    ];

    return acc;
  }, groupedLimits)
);
