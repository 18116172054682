import PropTypes from 'prop-types';
import { Card, Button } from 'bv-components';

const FormWrapper = ({
  className,
  onSubmit,
  headerTitle,
  content,
  submitButtonContent,
  buttonDisabled,
}) => (
  <form className={className} onSubmit={onSubmit}>
    <h1 className="bvs-header">{headerTitle}</h1>
    <Card>
      {content}
      <Button
        type="submit"
        className="sg-portal-form__button"
        primary
        disabled={buttonDisabled}
      >
        {submitButtonContent}
      </Button>
    </Card>
  </form>
);

FormWrapper.propTypes = {
  className: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  headerTitle: PropTypes.string.isRequired,
  content: PropTypes.element.isRequired,
  submitButtonContent: PropTypes.node.isRequired,
  buttonDisabled: PropTypes.bool,
};

FormWrapper.defaultProps = {
  buttonDisabled: false,
  className: null,
};

export default FormWrapper;
