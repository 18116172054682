import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { SECTIONS_TYPES } from '../../constants';
import { getSectionComponentFromSlug } from '../../helpers';
import SGPortalSectionRedux from './sg_portal_section_redux';
import GenericSection from '../generic_section';

const SGPortalSectionRenderer = (props) => {
  const {
    history,
    match: { params: { slug } },
    location,
  } = props;

  return (
    <SGPortalSectionRedux
      slug={slug}
      renderSection={({ section, ...rest }) => {
        if (section) {
          if (section.type === SECTIONS_TYPES.CUSTOM) {
            const SectionApp = getSectionComponentFromSlug(slug);

            if (!SectionApp) {
              return <Redirect to="/safer_gambling/portal" />;
            }

            return (
              <SectionApp
                {...section}
                {...rest}
                prevPathname={location.state ? location.state.prevPathname : null}
              />
            );
          }

          return (
            <GenericSection
              title={section.title}
              longDescription={section.longDescription}
            />
          );
        }

        history.push('/safer_gambling/portal');
        return null;
      }}
    />
  );
};

SGPortalSectionRenderer.propTypes = {
  match: PropTypes.instanceOf(Object).isRequired,
  history: PropTypes.instanceOf(Object).isRequired,
  location: PropTypes.instanceOf(Object).isRequired,
};

export default SGPortalSectionRenderer;
