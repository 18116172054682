import { getJSON, postJSON, deleteJSON } from 'bv-fetch';

export const fetchLimitTypes = () => getJSON('/api/loss_limits/types');
export const fetchLimitPeriods = () => getJSON('/api/loss_limits/periods');
export const createLimit = (params) => postJSON('/api/loss_limits', params);
export const fetchLimits = () => getJSON('/api/loss_limits');
export const createNoLimitLimit = (typeId) => (
  postJSON('/api/loss_limits/no_limit', { type_id: typeId })
);
export const fetchGeneralConfig = () => getJSON('/api/loss_limits/general_config');
export const fetchLimitConfigs = () => getJSON('/api/loss_limits/configs');
export const removeLimits = (ids) => deleteJSON('/api/loss_limits', { ids });
