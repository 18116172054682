import { Switch, Route } from 'react-router-dom';

import LimitHistory from 'LimitHistory/index';
import MainApp from './features/main/components/app_modal';
import SGPortalSectionRenderer from './components/sg_portal_section/sg_portal_section_renderer';
import reducer from './duck';

const { addReducers } = window.reduxState;

addReducers({
  saferGamblingPortal: reducer,
});

const SaferGamblingPortalApp = () => (
  <Switch>
    <Route exact path="/safer_gambling/portal" component={MainApp} />
    <Route exact path="/safer_gambling/portal/:slug" component={SGPortalSectionRenderer} />
    <Route exact path="/safer_gambling/portal/limit_history/:type" component={LimitHistory} />
  </Switch>
);

export default SaferGamblingPortalApp;
