import { connect } from 'react-redux';
import { compose } from 'redux';
import {
  fetchSections,
  handleChange,
  handleSubmit,
  closeSubmitResultModal,
  submitDepositLimitsForm,
} from '../../duck';
import { findSectionBySlug } from '../../selectors';
import SGPortalSectionModal from './sg_portal_section_modal';

const mapStateToProps = (state, ownProps) => ({
  section: findSectionBySlug(ownProps.slug)(state),
  sectionsLoaded: state.saferGamblingPortal.sectionsLoaded,
  sectionsAreLoading: state.saferGamblingPortal.sectionsAreLoading,
  settingsAreSubmitting: state.saferGamblingPortal.settingsAreSubmitting,
  settingsSubmitSucceed: state.saferGamblingPortal.settingsSubmitSucceed,
  settingsSubmitFailed: state.saferGamblingPortal.settingsSubmitFailed,
  submissionError: state.saferGamblingPortal.submissionError,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  closeSubmitResultModal: compose(dispatch, closeSubmitResultModal),
  fetchSections: compose(dispatch, fetchSections),
  handleChange: compose(dispatch, handleChange),
  handleSubmit: compose(
    dispatch,
    ownProps.slug.startsWith('deposit_limits') ? submitDepositLimitsForm : handleSubmit,
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(SGPortalSectionModal);
