import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Spinner } from 'bv-components';
import { useLoggedIn } from 'bv-hooks';
import { replaceFragment } from 'bv-helpers/location';
import SuccessSubmitModal from '../success_submit_modal';
import FailureSubmitModal from '../failure_submit_modal';
import { sectionType } from '../../types';

const SGPortalSection = (props) => {
  const loggedIn = useLoggedIn();

  const {
    renderSection,
    section,
    fetchSections,
    sectionsLoaded,
    sectionsAreLoading,
    handleChange,
    handleSubmit,
    settingsAreSubmitting,
    settingsSubmitSucceed,
    settingsSubmitFailed,
    closeSubmitResultModal,
    submissionError,
    slug,
  } = props;

  useEffect(() => {
    if (loggedIn && !sectionsLoaded) fetchSections();
  }, [loggedIn]);

  if (sectionsAreLoading || settingsAreSubmitting) return <Spinner />;

  // portal is unusable for non-logged-in users
  if (!loggedIn) {
    window.VC.afterLoginRedirect = `safer_gambling/portal/${slug}`;
    replaceFragment('login');

    return null;
  }

  return (
    <>
      {sectionsLoaded && renderSection({
        section,
        handleChange,
        handleSubmit,
      })}
      {settingsSubmitSucceed && <SuccessSubmitModal closeModal={closeSubmitResultModal} />}
      {settingsSubmitFailed && (
        <FailureSubmitModal error={submissionError} closeModal={closeSubmitResultModal} />
      )}
    </>
  );
};

SGPortalSection.propTypes = {
  slug: PropTypes.string.isRequired,
  renderSection: PropTypes.func.isRequired,
  section: sectionType,
  fetchSections: PropTypes.func.isRequired,
  sectionsLoaded: PropTypes.bool.isRequired,
  sectionsAreLoading: PropTypes.bool.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  settingsAreSubmitting: PropTypes.bool.isRequired,
  settingsSubmitSucceed: PropTypes.bool.isRequired,
  settingsSubmitFailed: PropTypes.bool.isRequired,
  closeSubmitResultModal: PropTypes.func.isRequired,
  submissionError: PropTypes.shape({
    message: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    generic: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  }).isRequired,
};

SGPortalSection.defaultProps = {
  section: null,
};

export default SGPortalSection;
