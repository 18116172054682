import {
  fetchLimitHistory,
} from './api';
import { groupLimitsByMonth } from './helpers';

const SET_ENABLED = 'limitHistory/SET_ENABLED';
const SET_LIMITS = 'limitHistory/SET_LIMITS';
const APPEND_LIMITS = 'limitHistory/APPEND_LIMITS';
const CLEAR_LIMITS = 'limitHistory/CLEAR_LIMITS';

export const setEnabled = (enabled, limitType) => ({
  type: SET_ENABLED,
  enabled,
  limitType,
});

const setLimits = ({ limits, meta }, limitType) => ({
  type: SET_LIMITS,
  limits,
  meta,
  limitType,
});

const appendLimits = ({ limits, meta }, limitType) => ({
  type: APPEND_LIMITS,
  limits,
  meta,
  limitType,
});

export const resetHistory = (limitType) => ({
  type: CLEAR_LIMITS,
  limitType,
});

export const loadInitialHistory = (type) => (dispatch) => (
  fetchLimitHistory(type, { size: 3 })
    .then((response) => dispatch(setLimits(response, type)))
);

export const loadHistory = (type, page) => (dispatch) => (
  fetchLimitHistory(type, { page })
    .then((response) => dispatch((page === 0 ? setLimits : appendLimits)(response, type)))
);

export const initialState = {};

export default (state = initialState, action = {}) => {
  const typeState = state[action.limitType] || {};
  switch (action.type) {
    case SET_ENABLED:
      return {
        ...state,
        [action.limitType]: {
          ...typeState,
          enabled: action.enabled,
        },
      };
    case CLEAR_LIMITS:
      return {
        ...state,
        [action.limitType]: {
          enabled: typeState?.enabled || false,
        },
      };
    case SET_LIMITS:
      return {
        ...state,
        [action.limitType]: {
          ...typeState,
          limits: groupLimitsByMonth(action.limits),
          meta: action.meta,
        },
      };
    case APPEND_LIMITS:
      return {
        ...state,
        [action.limitType]: {
          ...typeState,
          limits: groupLimitsByMonth(action.limits, { ...typeState.limits }),
          meta: action.meta,
        },
      };
    default:
      return state;
  }
};
