import LimitHistory from './components/limit_history';
import reducer from './duck';

const { addReducers } = window.reduxState;

addReducers({
  limitHistory: reducer,
});

export { default as CardSection } from './components/card_section';
export default LimitHistory;
