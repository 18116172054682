import PropTypes from 'prop-types';
import { Modal } from 'bv-components';
import { t } from 'bv-i18n';
import { setFragment } from 'bv-helpers/location';

const FailureSubmitModal = ({ error, closeModal }) => {
  let label;
  let handleClick;

  if (error.generic) {
    label = t('contact_us');
    handleClick = () => {
      closeModal();
      setFragment('pages/contact-us');
    };
  } else {
    label = t('javascript.close');
    handleClick = closeModal;
  }

  return (
    <Modal
      danger
      title={t('update_preferences_failed_title')}
      actions={[{
        id: 'ok-btn',
        label,
        danger: true,
        close: true,
        onClick: handleClick,
      }]}
    >
      <p className="bvs-text-error">{error.message}</p>
    </Modal>
  );
};

FailureSubmitModal.propTypes = {
  error: PropTypes.instanceOf(Object).isRequired,
  closeModal: PropTypes.func.isRequired,
};

export default FailureSubmitModal;
