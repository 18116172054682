import { connect } from 'react-redux';
import { isSectionValid } from 'SaferGamblingPortal/helpers';
import { fetchSections } from '../../../duck';
import App from './app';

const mapStateToProps = ({
  saferGamblingPortal: {
    sections,
    sectionsLoaded,
    sectionsAreLoading,
  },
}) => ({
  sections: sections.filter(isSectionValid),
  sectionsLoaded,
  sectionsAreLoading,
});

export default connect(mapStateToProps, { fetchSections })(App);
