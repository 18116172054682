import PropTypes from 'prop-types';
import ContactUsFooter from 'SharedComponents/contact_us_footer';
import DetailedDescription from '../../../components/detailed_description';
import { SelfExclusionApp } from '../../../lazy_components';

const SelfExclusion = ({ longDescription }) => (
  <div className="sg-portal-section--self_exclusion">
    <SelfExclusionApp
      showTitleWithSummary={false}
      isGamStopBrand={false}
      siteName={null}
      footer={(
        <>
          <DetailedDescription text={longDescription} textExpanded />
          <ContactUsFooter />
        </>
      )}
    />
  </div>
);

SelfExclusion.propTypes = {
  longDescription: PropTypes.string.isRequired,
};

export default SelfExclusion;
