import { fetchLimitOptions, fetchCurrentLimits } from './api';

const SET_CURRENT_LIMITS = 'depositLimits/SET_CURRENT_LIMITS';
const SET_LIMIT_OPTIONS = 'depositLimits/SET_LIMIT_OPTIONS';

const setCurrentLimits = (payload) => ({
  type: SET_CURRENT_LIMITS,
  payload,
});

const setLimitOptions = (payload) => ({
  type: SET_LIMIT_OPTIONS,
  payload,
});

export const loadCurrentLimits = () => (dispatch) => (
  fetchCurrentLimits()
    .then((response) => dispatch(setCurrentLimits(response)))
);

export const loadLimitOptions = () => (dispatch) => (
  fetchLimitOptions()
    .then((response) => dispatch(setLimitOptions(response)))
);

export const initialState = {
  currentLimits: null,
  limitOptions: null,
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case SET_CURRENT_LIMITS:
      return {
        ...state,
        currentLimits: action.payload,
      };
    case SET_LIMIT_OPTIONS:
      return {
        ...state,
        limitOptions: action.payload,
      };
    default:
      return state;
  }
};
