import PropTypes from 'prop-types';
import { useFetch } from 'bv-hooks';
import { Spinner } from 'bv-components';
import { setLocation } from 'bv-helpers/location';
import { SGQuestionnaireApp } from '../../../lazy_components';
import { getQuestionsData, apiStartInteractionWithQuestionnaire } from '../api';

const SGQuestionnaire = ({ prevPathname }) => {
  let successModalButtonLabelKey;
  let successRedirectUrl;

  const [questionsData, loading] = useFetch(getQuestionsData);

  if (loading) return <Spinner />;

  if (prevPathname && prevPathname === '/safer_gambling/portal') {
    successModalButtonLabelKey = 'javascript.sg.portal.QUESTIONNAIRE.back_to_safer_gambling_controls';
    successRedirectUrl = prevPathname;
  } else {
    successModalButtonLabelKey = 'javascript.sg.questionnaire.cta.result_ok_cta';
    successRedirectUrl = '/';
  }

  return (
    <div className="sg-portal-section--questionnaire">
      <SGQuestionnaireApp
        stateNamespace="saferGamblingQuestionnaire"
        translationsNamespace="javascript.sg.questionnaire"
        questionsIdentifiersList={questionsData.identifiers}
        questionnaireId={questionsData.id}
        handleSuccessModalClose={() => setLocation(successRedirectUrl)}
        handleFailureModalClose={() => setLocation('/pages/contact-us')}
        successModalButtonLabelKey={successModalButtonLabelKey}
        appliedMask="RG_CONCERN"
        onMountCallback={apiStartInteractionWithQuestionnaire}
      />
    </div>
  );
};

SGQuestionnaire.propTypes = {
  prevPathname: PropTypes.string,
};

SGQuestionnaire.defaultProps = {
  prevPathname: null,
};

export default SGQuestionnaire;
