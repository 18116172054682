import {
  shape, string, bool, oneOf,
} from 'prop-types';
import { SECTIONS_TYPES } from './constants';

// eslint-disable-next-line import/prefer-default-export
export const sectionType = shape({
  name: string.isRequired,
  title: string.isRequired,
  shortDescription: string.isRequired,
  longDescription: string.isRequired,
  slug: string.isRequired,
  loginRequired: bool.isRequired,
  type: oneOf([SECTIONS_TYPES.CUSTOM, SECTIONS_TYPES.TEXT]),
});
