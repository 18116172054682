import withLazyLoad from 'bv-lazy-load';
import { Spinner } from 'bv-components';

const loadWithFallback = withLazyLoad(<Spinner />);

export const SelfExclusionApp = loadWithFallback('saferGamblingPortal.features.selfExclusion');
export const TimeOutApp = loadWithFallback('saferGamblingPortal.features.timeOut');
export const ContactUsApp = loadWithFallback('saferGamblingPortal.features.contactUs');
export const OccupationAndSalaryApp = loadWithFallback('saferGamblingPortal.features.occupationAndSalary');
export const SGQuestionnaireApp = loadWithFallback('saferGamblingPortal.features.sgQuestionnaire');
export const AllowanceQuestionnaireApp = loadWithFallback('saferGamblingPortal.features.allowanceQuestionnaire');
export const StakeLimitsApp = loadWithFallback('saferGamblingPortal.features.stakeLimits');
export const LossLimitsApp = loadWithFallback('saferGamblingPortal.features.lossLimits');
export const DepositLimitsApp = loadWithFallback('saferGamblingPortal.features.depositLimits');
