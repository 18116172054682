import PropTypes from 'prop-types';
import { setLocation } from 'bv-helpers/location';
import { useFetch } from 'bv-hooks';
import { Spinner } from 'bv-components';
import { range } from 'underscore';
import { AllowanceQuestionnaireApp } from '../../../lazy_components';
import { fetchUserAllowanceQuestionnaire } from '../api';

const AllowanceQuestionnaire = ({ prevPathname }) => {
  const [data, questionnaireIsLoading] = useFetch(fetchUserAllowanceQuestionnaire);

  if (questionnaireIsLoading) return <Spinner />;

  let successModalButtonLabelKey;
  let successRedirectUrl;

  if (prevPathname && prevPathname === '/safer_gambling/portal') {
    successModalButtonLabelKey = 'javascript.sg.portal.QUESTIONNAIRE.back_to_safer_gambling_controls';
    successRedirectUrl = prevPathname;
  } else {
    successModalButtonLabelKey = 'javascript.sg.allowance_questionnaire.cta.result_ok_cta';
    successRedirectUrl = '/';
  }

  return (
    <div className="sg-portal-section--questionnaire">
      <AllowanceQuestionnaireApp
        stateNamespace="allowanceQuestionnaire"
        translationsNamespace="javascript.sg.allowance_questionnaire"
        questionsIdentifiersList={range(1, 6)}
        handleSuccessModalClose={() => setLocation(successRedirectUrl)}
        handleFailureModalClose={() => setLocation('/pages/contact-us')}
        successModalButtonLabelKey={successModalButtonLabelKey}
        appliedMask="EDD_EXTRA_ALLOWANCE"
        editable={data.editable}
      />
    </div>
  );
};

AllowanceQuestionnaire.propTypes = {
  prevPathname: PropTypes.string,
};

AllowanceQuestionnaire.defaultProps = {
  prevPathname: null,
};

export default AllowanceQuestionnaire;
