import PropTypes from 'prop-types';
import { formatAmount, formatDate } from '../helpers';

const ChangeList = ({
  groupLabels,
  groupedEntries,
}) => (
  <>
    {groupLabels.map((label, labelIndex) => (
      <div key={label}>
        <h2 className="bvs-h4">{label}</h2>
        {groupedEntries[label].map((entry, entryIndex, entries) => (
          <div className="limit-history__section" key={entry.id}>
            <div className="limit-history__statement">
              <p>{formatDate(entry.createdAt)}</p>
              {entry.subtype && (<span>{entry.subtype}</span>)}
            </div>
            <div className="limit-history__statement">
              <span className="limit-history__statement-type">{entry.type}</span>
              <span className="limit-history__statement-amount">{formatAmount(entry)}</span>
            </div>
            {(
              labelIndex < groupLabels.length - 1 || entryIndex < entries.length - 1
                ? <hr /> : null
            )}
          </div>
        ))}
      </div>
    ))}
  </>
);

ChangeList.propTypes = {
  groupLabels: PropTypes.arrayOf(PropTypes.string).isRequired,
  groupedEntries: PropTypes.arrayOf(PropTypes.instanceOf(Object)).isRequired,
};

export default ChangeList;
