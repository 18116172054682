import { getJSON, postJSON, deleteJSON } from 'bv-fetch';

export const fetchLimitPeriods = () => getJSON('/api/stake_limits/periods');
export const fetchLimitTypes = () => getJSON('/api/stake_limits/types');
export const createLimit = (params) => postJSON('/api/stake_limits', params);
export const createNoLimitLimit = (typeId) => (
  postJSON('/api/stake_limits/no_limit', { type_id: typeId })
);
export const fetchLimits = () => getJSON('/api/stake_limits');
export const removeLimits = (ids) => deleteJSON('/api/stake_limits', { ids });
export const fetchGeneralConfig = () => getJSON('/api/stake_limits/general_config');
export const fetchLimitConfigs = () => getJSON('/api/stake_limits/configs');
