import { t } from 'bv-i18n';
import ContactUsFooter from 'SharedComponents/contact_us_footer';
import AccountClosureInfo from './account_closure_info';
import SelfExclusionInfo from './self_exclusion_info';
import DetailedDescription from '../../../components/detailed_description';

const AccountClosureSE = () => {
  const tKeyPrefix = 'javascript.sg.portal.ACCOUNT_CLOSURE_SELF_EXCLUSION';

  return (
    <div className="sg-portal-section--account-closure-se">
      <AccountClosureInfo tKeyPrefix={tKeyPrefix} />
      <SelfExclusionInfo tKeyPrefix={tKeyPrefix} />
      <DetailedDescription text={t(`${tKeyPrefix}.long_description`)} />
      <ContactUsFooter />
    </div>
  );
};

export default AccountClosureSE;
