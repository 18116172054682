import PropTypes from 'prop-types';
import ContactUsFooter from 'SharedComponents/contact_us_footer';
import { CardSection } from 'LimitHistory/index';
import { StakeLimitsApp, LossLimitsApp } from '../../../lazy_components';
import DetailedDescription from '../../../components/detailed_description';
import * as stakeLimitsEndpoints from '../../../../stake_limits/api';
import * as lossLimitsEndpoints from '../../../../loss_limits/api';

const createApp = (Cmp, props) => {
  const App = ({ longDescription }) => (
    <div className="sg-portal-section--stake-limits">
      <Cmp
        {...props}
        footer={(
          <>
            <DetailedDescription text={longDescription} />
            {/* eslint-disable-next-line react/prop-types */}
            <CardSection type={props.translationsNamespace} />
            <ContactUsFooter />
          </>
          )}
      />
    </div>
  );

  App.propTypes = {
    longDescription: PropTypes.string.isRequired,
  };

  return App;
};

export const StakeLimits = createApp(
  StakeLimitsApp, {
    translationsNamespace: 'stake_limits',
    endpoints: stakeLimitsEndpoints,
  },
);

export const LossLimits = createApp(
  LossLimitsApp, {
    translationsNamespace: 'loss_limits',
    endpoints: lossLimitsEndpoints,
    scopePeriodsByTypeId: true,
  },
);
