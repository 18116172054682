import PropTypes from 'prop-types';
import { t } from 'bv-i18n';
import ContactUsSection from '../../../components/contact_us_section';

const AccountClosure = ({ longDescription }) => (
  <div className="sg-portal-section--account-closure">
    <ContactUsSection
      longDescription={longDescription}
      subtitle={t('javascript.sg.portal.ACCOUNT_CLOSURE.subtitle')}
    />
  </div>
);

AccountClosure.propTypes = {
  longDescription: PropTypes.string.isRequired,
};

export default AccountClosure;
