import { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { throttle } from 'underscore';
import { Redirect, useParams } from 'react-router-dom';
import { t } from 'bv-i18n';
import {
  Card, ModalWindow, Spinner,
} from 'bv-components';
import { limitType } from '../helpers';
import {
  getLimitLabels,
  getGroupedLimits,
  getCurrentPage,
  getHasMore,
  getEnabled,
} from '../selectors';
import { loadHistory, resetHistory } from '../duck';
import ChangeList from './change_list';

const LimitHistory = () => {
  const { type } = useParams();
  const dispatch = useDispatch();
  const isEnabled = useSelector((state) => getEnabled(state, type));
  const limitLabels = useSelector((state) => getLimitLabels(state, type));
  const groupedLimits = useSelector((state) => getGroupedLimits(state, type));
  const currentPage = useSelector((state) => getCurrentPage(state, type));
  const hasMore = useSelector((state) => getHasMore(state, type));

  const [page, setPage] = useState(currentPage);

  const scrollableRef = useRef();
  const listRef = useRef();

  const isValidType = Object.values(limitType).includes(type);

  const handleScroll = throttle(() => {
    if (page !== currentPage || !hasMore || !listRef.current) return;

    if (listRef.current.getBoundingClientRect().bottom < window.innerHeight) {
      setPage(currentPage + 1);
    }
  }, 500);

  const scrollToTop = () => scrollableRef.current?.scrollTo(0, 0);

  useEffect(() => {
    scrollableRef.current?.addEventListener('scroll', handleScroll);

    return () => {
      scrollableRef.current?.removeEventListener('scroll', handleScroll);
    };
  }, [hasMore, currentPage, scrollableRef.current]);

  useEffect(() => () => {
    if (isValidType) dispatch(resetHistory(type));
  }, []);

  useEffect(() => {
    if (isEnabled) dispatch(loadHistory(type, page));
  }, [type, page]);

  if (!isValidType || !isEnabled) return <Redirect to="/safer_gambling/portal" />;

  if (!limitLabels) return null;

  return (
    <ModalWindow
      title={t(`javascript.sg.portal.${type.toUpperCase()}.title`)}
      className="safer-gambling-portal-modal"
      scrollableRef={scrollableRef}
    >
      <div className="limit-history">
        <h1 className="bvs-header">{t('sg.portal.limit_history.title')}</h1>
        <Card>
          <div ref={listRef}>
            <ChangeList groupLabels={limitLabels} groupedEntries={groupedLimits} />
          </div>
        </Card>
        <div className="limit-history__spinner">
          {page !== currentPage && <Spinner big={false} />}
        </div>
        <div className="bvs-link limit-history__top-link" onClick={scrollToTop}>
          <span>{t('sg.portal.limit_history.back_to_top')}</span>
          <div className="limit-history__top-icon" />
        </div>
      </div>
    </ModalWindow>
  );
};

export default LimitHistory;
