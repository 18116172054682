import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Spinner, SliderSelector } from 'bv-components';
import ContactUsFooter from 'SharedComponents/contact_us_footer';
import { t } from 'bv-i18n';
import FormWrapper from '../../../components/form_wrapper';
import DetailedDescription from '../../../components/detailed_description';
import { fetchSettings as fetchSettingsFn } from '../../../duck';
import { findRealityCheckFrequencyOption } from '../../../selectors';

const RealityChecks = ({
  longDescription,
  handleChange,
  handleSubmit,
  realityChecksSettingsLoaded,
  fetchSettings,
  selectedRealityCheckFrequency,
  initialRealityCheckFrequency,
  realityChecks,
}) => {
  useEffect(() => {
    if (!realityChecksSettingsLoaded) fetchSettings();
  }, []);

  if (!realityChecksSettingsLoaded) return <Spinner />;

  return (
    <div className="sg-portal-section--reality-checks">
      <div className="sg-portal-section__wrapper with-separator">
        <h3 className="bvs-header">{t('javascript.sg.portal.REALITY_CHECK_CASINO.current')}</h3>
        <p>
          {t('javascript.sg.portal.REALITY_CHECK_CASINO.equal_to', {
            frequency: initialRealityCheckFrequency.title,
          })}
        </p>
      </div>

      <FormWrapper
        className="sg-portal-form sg-portal-form--reality-checks"
        onSubmit={handleSubmit}
        headerTitle={t('javascript.sg.portal.REALITY_CHECK_CASINO.set')}
        content={(
          <SliderSelector
            selections={realityChecks.options}
            selectedId={realityChecks.value}
            onChange={(e) => handleChange({
              name: realityChecks.name,
              value: Number(e.target.value),
            })}
            triggerWhileScrolling={false}
            isWiderItem
          />
        )}
        submitButtonContent={
          t('javascript.sg.portal.REALITY_CHECK_CASINO.set_to', {
            value: selectedRealityCheckFrequency.title,
          })
        }
      />
      <DetailedDescription text={longDescription} />
      <ContactUsFooter />
    </div>
  );
};

RealityChecks.propTypes = {
  realityChecks: PropTypes.arrayOf(Object).isRequired,
  longDescription: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  realityChecksSettingsLoaded: PropTypes.bool.isRequired,
  fetchSettings: PropTypes.func.isRequired,
  selectedRealityCheckFrequency: PropTypes.instanceOf(Object).isRequired,
  initialRealityCheckFrequency: PropTypes.instanceOf(Object).isRequired,
};

const mapStateToProps = (state) => {
  const {
    saferGamblingPortal: {
      settings: {
        realityChecks,
      },
      realityChecksSettingsLoaded,
    },
  } = state;

  let value;
  let initialValue;

  if (realityChecks) {
    value = realityChecks.value;
    initialValue = realityChecks.initialValue;
  }

  return {
    realityChecks,
    realityChecksSettingsLoaded,
    selectedRealityCheckFrequency: findRealityCheckFrequencyOption(value)(state),
    initialRealityCheckFrequency: findRealityCheckFrequencyOption(initialValue)(state),
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchSettings: () => dispatch(fetchSettingsFn({
    settingsType: 'reality_checks',
    loadingStatusProp: 'realityChecksSettingsLoaded',
  })),
});

export default connect(mapStateToProps, mapDispatchToProps)(RealityChecks);
