import { setLocation } from 'bv-helpers/location';
import { OccupationAndSalaryApp } from '../../../lazy_components';

const OccupationAndSalary = () => (
  <div className="sg-portal-section--occupation-and-salary">
    <OccupationAndSalaryApp
      onSuccessModalClose={() => setLocation('/')}
      renderInModal
    />
  </div>
);

export default OccupationAndSalary;
