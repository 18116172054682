import PropTypes from 'prop-types';
import { DepositLimitsApp } from '../../../lazy_components';

const DepositLimits = ({ handleChange, handleSubmit }) => (
  <div className="sg-portal-section--deposit-limits">
    <DepositLimitsApp
      handleChange={handleChange}
      handleSubmit={handleSubmit}
    />
  </div>
);

DepositLimits.propTypes = {
  handleChange: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

export default DepositLimits;
