import { useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Icon } from 'bv-components';
import { t } from 'bv-i18n';

const getFirstLine = (text) => `${text.slice(0, text.length - 3)}...`;

const DetailedDescription = (props) => {
  const { text, textExpanded: isTextExpanded } = props;
  const [textExpanded, toggleText] = useState(isTextExpanded);

  const textClassName = classnames('sg-portal-section__content', {
    'is-short': !textExpanded,
  });

  const headerClassName = classnames('sg-portal-section__header', {
    active: textExpanded,
  });

  return (
    <div className="sg-portal-section__wrapper">
      <div className="sg-portal-section__body" onClick={() => toggleText(!textExpanded)}>
        <div className={headerClassName}>
          <h1 className="bvs-header">{t('javascript.sg.portal.features.how_does_it_work?')}</h1>
          <Icon
            className="bvs-link"
            id={textExpanded ? 'arrow-solid-circle-up' : 'arrow-solid-circle-down'}
          />
        </div>
        <div className={textClassName}>
          {textExpanded ? (
            text.split('\n').map((line, index) => (
              <p id={index} className="bvs-p">{line}</p>
            ))
          ) : getFirstLine(text.split('\n')[0])}
        </div>
      </div>
    </div>
  );
};

DetailedDescription.propTypes = {
  text: PropTypes.string.isRequired,
  textExpanded: PropTypes.bool,
};

DetailedDescription.defaultProps = {
  textExpanded: false,
};

export default DetailedDescription;
