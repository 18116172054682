import PropTypes from 'prop-types';
import { Button, Card } from 'bv-components';
import { t } from 'bv-i18n';
import { accountClose } from '../../../duck';

const AccountClosureInfo = ({ tKeyPrefix }) => (
  <div className="account-closure-info">
    <h1 className="bvs-header">
      {t(`${tKeyPrefix}.account_closure.title`)}
    </h1>
    <Card>
      <p className="account-closure-info__text">
        {t(`${tKeyPrefix}.account_closure.contact_us`)}
      </p>
      <Button
        label={t(`${tKeyPrefix}.account_closure.close_account`)}
        primary
        onClick={() => accountClose()}
      />
    </Card>
  </div>
);

AccountClosureInfo.propTypes = {
  tKeyPrefix: PropTypes.string.isRequired,
};

export default AccountClosureInfo;
