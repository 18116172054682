import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Link, Spinner, Card, Icon,
} from 'bv-components';
import { t } from 'bv-i18n';
import { sectionType } from '../../../types';

const { CUSTOMER_CONSENT_URLS } = window.VCSTATE;

const App = ({
  sections,
  sectionsLoaded,
  sectionsAreLoading,
  fetchSections,
}) => {
  useEffect(() => {
    if (!sectionsLoaded) fetchSections();
  }, []);

  const { pathname } = useLocation();

  if (sectionsAreLoading) return <Spinner />;

  return (
    <div className="sg-portal">
      {sections.map((section) => (
        <div className="sg-portal__card-wrapper" key={section.name}>
          <div className="bvs-header">{section.title}</div>
          <Link
            to={{
              pathname: `/safer_gambling/portal/${section.slug}`,
              state: { prevPathname: pathname },
            }}
            data-react="true"
          >
            <Card className="sg-portal__card">
              <div className={`sg-portal__card-icon sg-portal__card-icon--${section.name.toLowerCase()}`} />
              <p className="sg-portal__card-description">
                {section.shortDescription}
              </p>
              <Icon id="arrow-right" />
            </Card>
          </Link>
        </div>
      ))}
      <div className="modal_contact_footer">
        <span>{t('javascript.sg.portal.homepage_footer.learn_more')}</span>
        <a href={CUSTOMER_CONSENT_URLS.responsible_gambling}>
          {t('javascript.sg.portal.homepage_footer.safer_gambling')}
        </a>
      </div>
    </div>
  );
};

App.propTypes = {
  sections: PropTypes.arrayOf(sectionType).isRequired,
  sectionsLoaded: PropTypes.bool.isRequired,
  sectionsAreLoading: PropTypes.bool.isRequired,
  fetchSections: PropTypes.func.isRequired,
};

export default App;
