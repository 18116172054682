import PropTypes from 'prop-types';
import ContactUsFooter from 'SharedComponents/contact_us_footer';
import DetailedDescription from '../../../components/detailed_description';
import { TimeOutApp } from '../../../lazy_components';

const TimeOut = ({ longDescription }) => (
  <div className="sg-portal-section--time-out">
    <TimeOutApp
      showTitleWithSummary={false}
      isGamStopBrand={false}
      siteName={null}
      footer={(
        <>
          <DetailedDescription text={longDescription} />
          <ContactUsFooter />
        </>
      )}
    />
  </div>
);

TimeOut.propTypes = {
  longDescription: PropTypes.string.isRequired,
};

export default TimeOut;
