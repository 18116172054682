import PropTypes from 'prop-types';
import { ModalWindow } from 'bv-components';
import SGPortalSection from './sg_portal_section';

/* eslint react/destructuring-assignment:off */
const SGPortalSectionModal = (props) => (
  <ModalWindow
    title={props.section ? props.section.title : ''}
    className="safer-gambling-portal-modal"
  >
    <SGPortalSection {...props} />
  </ModalWindow>
);

SGPortalSectionModal.propTypes = {
  section: PropTypes.instanceOf(Object).isRequired,
};

export default SGPortalSectionModal;
