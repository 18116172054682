import DepositLimits from './features/deposit_limits/components/deposit_limits';
import RealityChecks from './features/reality_checks/components/reality_checks';
import SelfExclusion from './features/self_exclusion/components/self_exclusion';
import CancelWithdrawal from './features/cancel_withdrawal/components/cancel_withdrawal';
import TimeOut from './features/time_out/components/time_out';
import ProductRestriction from './features/product_restriction/components/product_restriction';
import GamblingSupport from './features/gambling_support/components/gambling_support';
import ProblemCheckList from './features/problem_check_list/components/problem_check_list';
import SGQuestionnaire from './features/sg_questionnaire/components/sg_questionnaire';
import GenericSection from './components/generic_section';
import OccupationAndSalary from './features/occupation_and_salary/components/occupation_and_salary';
import AllowanceQuestionnaire from './features/allowance_questionnaire/components/allowance_questionnaire';
import AccountClosure from './features/account_closure/components/account_closure';
import AccountClosureSE from './features/account_closure_se/components/account_closure_se';
import { StakeLimits, LossLimits } from './features/stake_limits/components/stake_limits';
import { SECTIONS_TYPES } from './constants';

export const getSectionComponentFromSlug = (slug) => ({
  deposit_limits: DepositLimits,
  reality_checks: RealityChecks,
  self_exclusion: SelfExclusion,
  cancel_withdrawal: CancelWithdrawal,
  time_out: TimeOut,
  product_restriction: ProductRestriction,
  gambling_support: GamblingSupport,
  problem_check_list: ProblemCheckList,
  questionnaire: SGQuestionnaire,
  under_age_gambling: GenericSection,
  keep_track_of_time: GenericSection,
  occupation_and_salary: OccupationAndSalary,
  allowance_questionnaire: AllowanceQuestionnaire,
  account_closure: AccountClosure,
  account_closure_se: AccountClosureSE,
  // TODO: Old sections cleanup will be accomplished in separate task
  stake_limits: StakeLimits,
  loss_limits: LossLimits,
}[slug]);

export const isSectionValid = (section) => {
  if (section.type === SECTIONS_TYPES.CUSTOM) {
    return Boolean(getSectionComponentFromSlug(section.slug));
  }

  return true;
};
