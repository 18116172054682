import { createSelector } from 'reselect';
import { pick } from 'underscore';

export const getSections = (state) => state.saferGamblingPortal.sections;
export const getSettings = (state) => state.saferGamblingPortal.settings;

export const getSubmittableSettings = createSelector(
  getSettings, (settings) => pick(settings, (val) => val && val.name && val.value != null),
);

export const findSectionBySlug = (slug) => createSelector(
  getSections, (sections) => sections.find((section) => section.slug === slug),
);

export const getSettingsNamedValues = createSelector(
  getSubmittableSettings, (settings) => Object.values(settings).reduce((namedValues, entry) => ({
    ...namedValues,
    [entry.name]: entry.value,
  }), {}),
);

export const getRealityChecks = createSelector(
  getSettings, (settings) => settings.realityChecks,
);

export const findRealityCheckFrequencyOption = (id) => createSelector(
  getRealityChecks, (realityChecks) => {
    if (realityChecks) {
      return realityChecks.options.find((option) => option.id === id);
    }

    return null;
  },
);
