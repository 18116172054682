import { getJSON, postJSON } from 'bv-fetch';
import { locale } from 'bv-i18n';

export const fetchSettings = (settingsType) => (
  getJSON(`safer_gambling/portal/${settingsType}`)
);

export const fetchSGPortalConfiguration = () => (
  getJSON(`/security_checks/${locale()}/safer_gambling/portal/configuration`)
);

export const closeAccount = () => postJSON('/safer_gambling/portal/self_close');
