import PropTypes from 'prop-types';
import DetailedDescription from './detailed_description';
import { ContactUsApp } from '../lazy_components';

const ContactUsSection = ({ longDescription, subtitle }) => (
  <ContactUsApp
    header={(
      <div className="sg-portal-section__wrapper">
        {subtitle}
      </div>
    )}
    footer={<DetailedDescription text={longDescription} />}
    setModalTitle={false}
  />
);

ContactUsSection.propTypes = {
  longDescription: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
};

export default ContactUsSection;
