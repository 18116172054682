import PropTypes from 'prop-types';
import { t } from 'bv-i18n';
import ContactUsSection from '../../../components/contact_us_section';

const CancelWithdrawal = ({ longDescription }) => (
  <div className="sg-portal-section--cancel-withdrawal">
    <ContactUsSection
      longDescription={longDescription}
      subtitle={t('javascript.sg.portal.features.contact_us_for_enabling')}
    />
  </div>
);

CancelWithdrawal.propTypes = {
  longDescription: PropTypes.string.isRequired,
};

export default CancelWithdrawal;
