import PropTypes from 'prop-types';
import { Modal } from 'bv-components';
import { t } from 'bv-i18n';

const SuccessSubmitModal = ({ closeModal }) => (
  <Modal
    success
    actions={[{
      id: 'ok-btn',
      label: t('javascript.close'),
      primary: true,
      close: true,
      onClick: closeModal,
    }]}
  >
    <p className="bvs-text-success">
      {t('your_mobile_preferences_have_been_updated')}
    </p>
  </Modal>
);

SuccessSubmitModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
};

export default SuccessSubmitModal;
