import PropTypes from 'prop-types';
import { t } from 'bv-i18n';
import { SelfExclusionApp } from '../../../lazy_components';

const SelfExclusionInfo = ({ tKeyPrefix }) => (
  <div className="self-exclusion-info">
    <h1 className="bvs-header">
      {t(`${tKeyPrefix}.irreversible_self_exclusion.title`)}
    </h1>
    <SelfExclusionApp
      showTitleWithSummary={false}
      isGamStopBrand={false}
      siteName={null}
    />
  </div>
);

SelfExclusionInfo.propTypes = {
  tKeyPrefix: PropTypes.string.isRequired,
};

export default SelfExclusionInfo;
