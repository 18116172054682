import { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Card, Button } from 'bv-components';
import { useHistory } from 'react-router-dom';
import ContactUsFooter from 'SharedComponents/contact_us_footer';
import { t } from 'bv-i18n';

const ProblemCheckList = ({ longDescription }) => {
  const history = useHistory();

  return (
    <div className="sg-portal-section--problem-check-list">
      {longDescription.split('\n').map((line, index) => (
        <Fragment id={index}>
          <p className="bvs-p">{line}</p>
          <hr />
        </Fragment>
      ))}
      <Card>
        <p className="bvs-p">
          {t('javascript.sg.portal.PROBLEM_CHECK_LIST.additional_info.paragraphs.1')}
        </p>
        <p className="bvs-p">
          {t('javascript.sg.portal.PROBLEM_CHECK_LIST.additional_info.paragraphs.2')}
        </p>
        <Button
          primary
          label={t('javascript.sg.portal.PROBLEM_CHECK_LIST.back_to_safer_gambling_controls')}
          onClick={() => history.push('/safer_gambling/portal')}
        />
      </Card>
      <ContactUsFooter />
    </div>
  );
};

ProblemCheckList.propTypes = {
  longDescription: PropTypes.string.isRequired,
};

export default ProblemCheckList;
